import { useState, useEffect } from "react";
import {
  Location,
  LocationsApiResponse,
} from "../models/app";
import { getLocations } from "../services/GetLocations";
import { useDispatch, useSelector } from "react-redux";
import {
  updateLocations,
  updateSelectedLocation,
  updateUserSystemId,
} from "../store/ducks/app";
import { RootState } from "../store/ducks";

export const useGetLocations = () => {
  const selectedLocationStore = useSelector(
    (state: RootState) => state?.store?.selectedLocation
  );
  const userStore = useSelector((state: RootState) => state?.store?.user);


  const dispatch = useDispatch();

  const [locations, setLocations] = useState<Location[] | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    JSON.parse(localStorage.getItem("location") ?? "[]") ??
      (locations && locations.length !== 0 ? (locations ? [0] : null) : null)
  );
  const [locationsLoading, setLoading] = useState(true);
  const [locationsError, setError] = useState<string | null>(null);

  const fetchData = async () => {
    try {
      
      setLoading(true);
      const apiResponse: LocationsApiResponse = await getLocations();
      const values: Location[] = apiResponse.Data.Value;
      setLocations(values);
      if (selectedLocationStore) {
        const locationCode = selectedLocation?.code;
        const newSelectedLocation = values.find(
          (location: Location) => location.code === locationCode
        );
        if (newSelectedLocation) {
          setSelectedLocation(newSelectedLocation);
          dispatch(updateSelectedLocation(newSelectedLocation));
        } else {
          setSelectedLocation(selectedLocationStore);
          dispatch(updateSelectedLocation(selectedLocationStore));
        }
      } else {
        const locRef = window?.location.hostname;
        const loc = locRef.split(".");
        const locName = loc[0];
        const location = values?.find(
          (location: Location) =>
            location.name.toLowerCase().trim() === locName.toLowerCase().trim()
        );
        if (location) {
          setSelectedLocation(location);
          dispatch(updateSelectedLocation(location));
        } else {
          console.log('values' , values)
          setSelectedLocation(values[0]);
          dispatch(updateSelectedLocation(values[0]));
        }
      }

      dispatch(updateLocations(values));
      if (userStore !== null && userStore !== "" && userStore !== "guest") {
        // console.log("getLocations hook user: ", userStore.id);
        dispatch(updateUserSystemId(userStore.id));
      }
      else{
        dispatch(updateUserSystemId(values[0].guestCutomerId));
      }
      // localStorage.setItem('selectedLocation', JSON.stringify(values[0]));
      // localStorage.setItem('userSystemId', values[0].guestCutomerId);
      // localStorage.setItem('locationsList', JSON.stringify(values));
    } catch (error) {
      setError("Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const locationsStorage = JSON.parse(
      localStorage.getItem("locationsList") ?? "[]"
    );
    const selectedLocationStorage =
      JSON.parse(localStorage.getItem("location") ?? "[]") ??
      (locations && locations.length !== 0 ? (locations ? [0] : null) : null);
    const userSystemId = localStorage.getItem("userSystemId");
    if (!(locationsStorage && selectedLocationStorage && userSystemId)) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, []);

  return {
    locations,
    selectedLocation,
    setSelectedLocation,
    locationsLoading,
    locationsError,
    fetchData,
  };
};
