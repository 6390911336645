import React, {useEffect, useState} from "react";
import CatalogSideBar from "../../components/CatalogSideBar";
import {Product} from "../../models/app";
import {useSearchParams} from "react-router-dom";
import TireDetailsPage from "../TireDetailsPage/TireDetailsPage";
import {useProducts} from "../../hooks/useGetProducts";
import Loader from "../../components/UI/loader/Loader";
import EmptyShop from "../../components/EmptyShop";
import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Pagination,
    Select,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import {RootState} from "../../store/ducks";
import {useSelector} from "react-redux";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {Helmet} from "react-helmet";
import NewSearch from "../../components/NewSearch/NewSearch";

const EnhancedSortControl = ({
                                 sortCriteria,
                                 setSortCriteria,
                                 sortDirection,
                                 setSortDirection,
                             }: any) => {
    return (
        <Box display="flex" alignItems="center" gap={2}>
            <FormControl variant="outlined" size="small" sx={{minWidth: 120}}>
                <InputLabel id="sort-criteria-label">Sort By</InputLabel>
                <Select
                    labelId="sort-criteria-label"
                    id="sort-criteria"
                    value={sortCriteria === "customprice" ? "model" : sortCriteria}
                    label="Sort By"
                    onChange={(e) => setSortCriteria(e.target.value)}
                >
                    <MenuItem value="price">Price</MenuItem>
                    <MenuItem value="brand">Brand</MenuItem>
                    <MenuItem value="model">Model</MenuItem>
                    <MenuItem value="availability">Availability</MenuItem>
                </Select>
            </FormControl>
            <ToggleButtonGroup
                value={sortDirection}
                exclusive
                onChange={(event, newDirection) => {
                    if (newDirection !== null) {
                        setSortDirection(newDirection);
                    }
                }}
                aria-label="text alignment"
                size="small"
            >
                <ToggleButton
                    value="asc"
                    aria-label="ascending"
                    sx={{height: "40px"}}
                >
                    <ArrowUpwardIcon/>
                </ToggleButton>
                <ToggleButton
                    value="desc"
                    aria-label="descending"
                    sx={{height: "40px"}}
                >
                    <ArrowDownwardIcon/>
                </ToggleButton>
            </ToggleButtonGroup>
            <Typography variant="subtitle2">
                {sortDirection === "asc" ? "Ascending" : "Descending"}
            </Typography>
        </Box>
    );
};

const ShopPage: React.FC = () => {
    const {
        filterKeyMap,
        filters,
        products,
        updateSelectedFilters,
        initialFilters,
        toggleRunFlat,
        toggleShowExternal,
        toggleInStock,
        filteredProducts,
        applyFilters,
        getLocationsQuantities,
        productsLoading,
        productsError,
        sortCriteria,
        setSortCriteria,
        sortDirection,
        setSortDirection,
        toggleSortDirection,
        fetchData,
    } = useProducts();

    const userSystemIdStore = useSelector(
        (state: RootState) => state?.store?.userSystemId
    );

    const selectedLocationStore = useSelector(
        (state: RootState) => state?.store?.selectedLocation
    );

    const [searchParams] = useSearchParams();
    
    const [loading, setLoading] = useState<boolean>(true);
    const [inStock, setInStock] = useState<boolean>(false);

    useEffect(() => {
       
        async function fetchDataAndSetLoading() {
            setLoading(true);
            const searchInput = JSON.parse(searchParams?.get("searchInput") ?? `""`);
            const catalog = JSON.parse(searchParams?.get("catalog") ?? `""`);

            // console.log(searchInput, catalog, userSystemIdStore);
            if (!catalog) {
                const searchInputRear = JSON.parse(
                    searchParams?.get("searchInputRear") ?? `""`
                );
                // console.log(searchInputRear);
                fetchData(
                    searchInput ?? "",
                    searchInputRear ?? "",
                    userSystemIdStore ?? "",
                    false,
                    inStock ?? true
                ).then(async () => {
                    await new Promise((resolve) => setTimeout(resolve, 100));
                    if(JSON.parse(searchParams?.get("searchInputRear") ?? `""`).Width!="" || JSON.parse(searchParams?.get("searchInputRear") ?? `""`).RawSize!="")
                    {
                        setSortCriteria("customprice");
                    }
                    setLoading(false)
                });
            } else {
                const searchInputRear = {
                    Width: "",
                    Ratio: "",
                    Wheel: "",
                    Season: "",
                    speedRating: "",
                    loadIndex: "",
                    RawSize: "",
                };
                fetchData(
                    searchInput ?? "",
                    searchInputRear ?? "",
                    userSystemIdStore ?? "",
                    true,
                    inStock ?? true
                ).then(async () => {
                    await new Promise((resolve) => setTimeout(resolve, 100));
                    if(JSON.parse(searchParams?.get("searchInputRear") ?? `""`).Width!="" || JSON.parse(searchParams?.get("searchInputRear") ?? `""`).RawSize!="")
                    {
                        setSortCriteria("customprice");
                    }
                    setLoading(false)
                });
            }
            // setLoading(true);
        }

        fetchDataAndSetLoading();
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [userSystemIdStore, inStock, selectedLocationStore]);

    // const [newSearch, setNewSearch] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState<any>({});
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [runFlat, setRunFlat] = useState<boolean>(false);
    const [showExternal, setShowExternal] = useState<boolean>(true);
    const [totalPages, setTotalPages] = useState<number>(
        Math.ceil(
            (filteredProducts?.length ?? products?.length ?? 0) / itemsPerPage
        )
    );
    const [indexOfLastItem, setIndexOfLastItem] = useState<number>(
        page === totalPages
            ? filteredProducts?.length ?? products?.length
            : page * itemsPerPage
    );
    const [indexOfFirstItem, setIndexOfFirstItem] = useState<number>(
        indexOfLastItem - itemsPerPage
    );
    const [currentItems, setCurrentItems] = useState<Product[]>(
        filteredProducts
            ?.slice(indexOfFirstItem, indexOfLastItem)
            .sort((a, b) => (a.UnitPrice - b.UnitPrice)) ?? []
    );
    const [sidebarTop, setSidebarTop] = useState("140px");


    const handleChangePage = (
        event: React.ChangeEvent<unknown>,
        newPage: number
    ) => {
        setPage(newPage);
    };

    useEffect(() => {
        setPage(1);
    }, [selectedFilters, runFlat, inStock]);

    useEffect(() => {
        // setLoading(true);
        // const calculatePagination = () => {
        const newTotalPages = Math.ceil(
            (filteredProducts?.length ?? products?.length ?? 0) / itemsPerPage
        );
        setTotalPages(newTotalPages);

        const newIndexOfFirstItem = (page - 1) * itemsPerPage;
        const newIndexOfLastItem = page * itemsPerPage;

        const newCurrentItems = filteredProducts?.length
            ? filteredProducts.slice(newIndexOfFirstItem, newIndexOfLastItem)
            : [];
        setCurrentItems(newCurrentItems);
        // };

        window.scrollTo({top: 0, behavior: 'smooth'});

        // calculatePagination();
        // console.log("loading shop done");
        // setLoading(false);
    }, [page, filteredProducts, products, itemsPerPage]);

    if ((productsLoading === true) || (loading === true)) return <Loader/>;
    else {
        return (
            <div style={{}}>
                <Helmet>
                    <title>Catalog</title>
                    {currentItems.map((item) => (
                        <meta key={item.id} name="item" content={item.name}/>
                    ))}
                    <meta charSet="UTF-8"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
                    <meta name="format-detection" content="telephone=no"/>
                    <meta name="robots" content="index, follow"/>

                    <meta property="og:locale" content="en_US"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content="Home - Tire Zone"/>
                    <meta property="og:url" content="https://tirezoneusa.com/shop"/>
                    <meta property="og:site_name" content="Tire Zone"/>
                    <meta property="article:publisher"
                          content="https://www.facebook.com/pages/Tire-Zone/167358669946020"/>
                    <meta property="article:modified_time" content="2023-01-09T22:34:45+00:00"/>
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:site" content="@tirezoneusa"/>
                </Helmet>
                <body>
                <div className="boxed_wrapper">
                    {/* <PageTitle title={"Catalog"} /> */}
                    <section className="shop-page-section p_relative">
                        <div className="auto-container" style={{padding: "-10px"}}>
                            <Grid container spacing={2} mt={1} className="wrapper">

                                <Grid item xs={12} lg={3} className="sidebar">
                                    <Box my={1}>
                                        <NewSearch/>
                                    </Box>
                                    <CatalogSideBar
                                        updateSelectedFilters={updateSelectedFilters}
                                        toggleRunFlat={toggleRunFlat}
                                        toggleShowExternal={toggleShowExternal}
                                        toggleInStock={toggleInStock}
                                        filters={filters}
                                        selectedFilters={selectedFilters}
                                        setSelectedFilters={setSelectedFilters}
                                        runFlat={runFlat}
                                        setRunFlat={setRunFlat}
                                        showExternal={showExternal}
                                        setShowExternal={setShowExternal}
                                        inStock={inStock}
                                        setInStock={setInStock}
                                        applyFilters={applyFilters}
                                        initialFilters={initialFilters}
                                        filterKeyMap={filterKeyMap}
                                    />
                                </Grid>

                                {(!currentItems || currentItems.length === 0) && (
                                    <Grid
                                        item
                                        xs={12}
                                        lg={9}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-around",
                                            alignItems: "center"
                                        }}
                                    >
                                        <EmptyShop/>

                                        <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                                            {/* <NewSearch/> */}
                                        </Grid>
                                    </Grid>
                                )}

                                {products &&
                                    products.length !== 0 &&
                                    currentItems &&
                                    currentItems.length !== 0 && (
                                        <Grid item xs={12} lg={9} className="main">
                                            <div className="our-shop">
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <Grid item xs={12} lg={4}>
                                                        <div className="text">
                                                            <p className="fs_16 font_family_poppins">
                                                                {`Showing `}
                                                                <span className="color_black">
                                    {(page - 1) * itemsPerPage + 1} –{" "}
                                                                    {Math.min(
                                                                        page * itemsPerPage,
                                                                        filteredProducts?.length ?? products?.length
                                                                    )}
                                  </span>
                                                                {` of `}
                                                                <span className="color_black">
                                    {filteredProducts?.length ?? products?.length}
                                  </span>{" "}
                                                                {` Results `}
                                                            </p>
                                                        </div>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        lg={8}
                                                        // sx={{ display: "flex", justifyContent: "flex-end" }}
                                                    >
                                                        <Grid container
                                                        > {/**sx={{ display: "flex", justifyContent: "flex-end" }} */}
                                                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                                                {/* <NewSearch/> */}
                                                            </Grid>
                                                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}
                                                                  sx={{display: "flex", justifyContent: "flex-end"}}>
                                                                <EnhancedSortControl
                                                                    sortCriteria={sortCriteria}
                                                                    setSortCriteria={setSortCriteria}
                                                                    sortDirection={sortDirection}
                                                                    setSortDirection={setSortDirection}
                                                                    toggleSortDirection={toggleSortDirection}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <br/>
                                                <div className="wrapper list">
                                                    <Grid container spacing={1} mt={1}
                                                          style={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                                                        {currentItems.map((product) => (
                                                            <Grid xs={12} mx={1} my={2} p={2} style={{
                                                                backgroundColor: 'white',
                                                                borderRadius: '5px',
                                                                boxShadow: '0 4px 8px 0 lightgray, 0 6px 20px 0 lightgray'
                                                            }} key={product.SystemId}>
                                                                <TireDetailsPage
                                                                    item={product}
                                                                    showExternal = {showExternal}
                                                                    getLocationsQuantities={
                                                                        getLocationsQuantities
                                                                    }
                                                                />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </div>
                                            </div>
                                        </Grid>
                                    )}

                                <Grid item xs={12}>
                                    <Stack
                                        spacing={2}
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginY: 4,
                                        }}
                                    >
                                        <Pagination
                                            count={totalPages}
                                            page={page}
                                            onChange={handleChangePage}
                                            size="large"
                                            sx={{".MuiPaginationItem-root": {fontSize: "1.2rem"}}}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                            {/* <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Dialog
                      open={newSearch}
                      onClose={()=>setNewSearch(false)}
                      fullWidth
                      maxWidth="lg"
                      PaperProps={{
                        sx: { maxHeight: 'calc(100vh - 60px)', overflowY: 'auto' },
                        className: 'custom-scrollbar' // Applies the custom scrollbar styles
                      }}
                    >
                      <IconButton
                        onClick={()=>setNewSearch(false)}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <CustomDialogContent>
                        <Grid container spacing={2} justifyContent="center">
                          <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                            <HomePageFilters />
                          </Grid>
                        </Grid>
                      </CustomDialogContent>
                    </Dialog>
                  </ThemeProvider> */}
                        </div>
                    </section>
                </div>
                </body>
            </div>
        );
    }
};

export default ShopPage;
