import React from 'react';
import { Switch, FormControlLabel, Accordion, AccordionSummary, Typography } from '@mui/material';
import { OptionalServices } from '../../../models/app';

interface MultiToggleProps {
  fields: OptionalServices[];
  selectAll: boolean;
  handleToggleAllChange: () => void; 
  onToggleChange: (index: number) => void;
  expandedIndex: number | null;
  setExpandedIndex: (index: number | null) => void;
}

const MultiToggle: React.FC<MultiToggleProps> = ({ fields, selectAll, handleToggleAllChange, onToggleChange }) => {
  
    return (
      <div >
          <FormControlLabel
              aria-label="Acknowledge"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={
              <Switch 
                  checked={!selectAll}
                  disabled={!selectAll}
                  onChange={handleToggleAllChange} 
                  color="primary"
                  sx={{ '& .MuiSwitch-switchBase.Mui-checked': {
                  color: 'green',
                  '& + .MuiSwitch-track': {
                      backgroundColor: 'green',
                  },
                  }}}
              />
              }
              label= { "Select None"}
              sx={{ marginLeft: 'auto' }}
          />
        <div className='multiToggle_grid'>

        {fields?.map((field, index) => (
          <Accordion disableGutters key={index} sx={{width:"100%", margin:"0"}}>
            <AccordionSummary sx={{margin:"0px"}} >
              <Typography sx={{ width: '60%', flexShrink: 0 }}>
                {field.Type==="Optional"?field.ServiceDescription:`${field.ServiceDescription}`}
              </Typography>
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <Switch 
                    checked={field.Selected} 
                    onChange={() => onToggleChange(index)} 
                    color="primary"
                    sx={{ '& .MuiSwitch-switchBase.Mui-checked': {
                      color: 'green',
                      '& + .MuiSwitch-track': {
                        backgroundColor: 'green',
                      },
                    }}}
                  />
                }
                label={
                  field.CalcType === "Fixed"
                    ? `$${field.ServicePriceWithTax.toFixed(2)}`
                   
                    : `$${field.ServicePriceWithTax.toFixed(2)} /each`
                }
                sx={{ margin: "0px" }}
              />
            </AccordionSummary>
            {/* <AccordionDetails>
              <Typography>
                {field.ServiceDescription}
              </Typography>
            </AccordionDetails> */}
          </Accordion>
        ))}
        </div>
      </div>
    );
    // else {return(
    //   <Container maxWidth="sm" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '30vh' }}>
    //   <Typography variant="h5" component="h2" gutterBottom>
    //     No Optional Services Available
    //   </Typography>
    // </Container>
    // )}
};


export default MultiToggle;
