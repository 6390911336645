import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogContent, Grid, IconButton, Typography, useTheme } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import MultiToggle from "../cart/OptionalServices/MultiToggle";
import { CartItem, Location, OptionalServices, Product } from "../../models/app";
import { addToCart, clearCart } from "../../store/ducks/app";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/ducks";
import { useNavigate } from "react-router-dom";

function OptionalServicesModal({product, updateCart, calculateTotal, fields, open, setOpen, setOptions, quantity, isCart, showConfirmationModal, setShowConfirmationModal }: any) {
    const [fieldsState, setFieldsState] =useState<OptionalServices[]>([]);
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
    const [selectAll, setSelectAll] =useState<boolean>(fields.some((field: OptionalServices) => field.Selected));
    const dispatch = useDispatch();
    const selectedLocation:Location = useSelector((state: any) => state?.store?.selectedLocation); 
    const user = useSelector((state: RootState) => state?.store?.user);

    const theme = useTheme();

    const getHeight = () => {
      const { breakpoints } = theme;
      if (breakpoints.down('sm')) {
        return '280px'; // Height for xs and sm screens
      } else if (breakpoints.down('md')) {
        return '350px'; // Height for md screens
      } else {
        return '400px'; // Height for lg and xl screens (adjust as needed)
      }
    };

    const getFontSize = () => {
      const { breakpoints } = theme;
      if (breakpoints.down('sm')) {
        return '12px'; // Smaller font size for mobile screens
      } else {
        return '16px'; // Default font size for larger screens
      }
    };

  const handleToggleChange = (index: number) => {
    // console.log(product);
    const updatedFields = fields.map((field:OptionalServices, i:any) =>
      i === index ? { ...field, Selected: !field.Selected } : field
    ); //&& field.Type==="Optional"
    setOptions(updatedFields);
    product.services= updatedFields;
    updateCart(product);
    if(updatedFields.some((field: OptionalServices) => field.Selected)){
      setSelectAll(true);
    }
    else{
      setSelectAll(false);
    }
  };

  const handleToggleAllChange = () => {
    const updatedFields = fields.map((field:OptionalServices, i:any) =>
      field.Type==="Optional" ? { ...field, Selected: false } : { ...field, Selected: false }
    );
    setSelectAll(!selectAll);
    setOptions(updatedFields);
    product.services= updatedFields;
    updateCart(product);
  };
  const navigate = useNavigate();
  const handleAddToCart = async (item: CartItem,redirect : boolean) => {
    const cart:CartItem[] = JSON.parse(localStorage.getItem("cart")??"[]");
    if(user === "" || user==="guest" ){
      if((cart.length === 0)||((cart.length > 0) && (selectedLocation.code === cart[0].locationCode))){
        const newItem:CartItem = { brand:item.brand, showMessage : item.showMessage, locationCode: selectedLocation.code, id: item?.id??"", no:item?.no??"", name: item?.name??"Product Name", quantity: quantity??0, image: item?.image??"../../assets/images/product/blog-1.jpg", description: item?.description??"", price: item?.price??0, services: fields, salesTax: item?.salesTax, additionalTax1: item?.additionalTax1, additionalTax1Caption: item?.additionalTax1Caption, additionalTax2: item?.additionalTax2, additionalTax2Caption: item?.additionalTax2Caption };
        // console.log(" 1 ", item, newItem);
        dispatch(addToCart(newItem));
        setOpen(false);
        if(redirect == true)
          navigate('/cart')
      }
      else{
        setShowConfirmationModal(true);
      }    
    }
    else{
      // console.log(" else 2 ");
      if((cart.length === 0)||((cart.length > 0)  && (selectedLocation) && (selectedLocation?.code === cart[0].locationCode))){
          const newItem:CartItem = { brand:item.brand, showMessage : item.showMessage,locationCode: selectedLocation?.code, id: item?.id??"", no:item?.no??"", name: item?.name??"Product Name", quantity: quantity??0, image: item?.image??"../../assets/images/product/blog-1.jpg", description: item?.description??"", price: item?.price??0, services: fields, salesTax: item?.salesTax, additionalTax1: item?.additionalTax1, additionalTax1Caption: item?.additionalTax1Caption, additionalTax2: item?.additionalTax2, additionalTax2Caption: item?.additionalTax2Caption };
          // console.log(" 1 ", item, newItem);
          dispatch(addToCart(newItem));
          setOpen(false);
          if(redirect == true)
            navigate('/cart')
      }
      else{
          setShowConfirmationModal(true);
      }
    }
    
  };


  const handleStillAddToCart = async (item: Product,redirect : boolean) => {
    setOpen(true);
    var newItem:CartItem = { showMessage : item.showMessage,locationCode: selectedLocation.code, id: item?.id??"", no:item?.no??"", name: item?.name??"Product Name", quantity: quantity??0, image: item?.image??"../../assets/images/product/blog-1.jpg", description: item?.description??"", price: item?.price??0, services: fields, salesTax: item?.salesTax, additionalTax1: item?.additionalTax1, additionalTax1Caption: item?.additionalTax1Caption, additionalTax2: item?.additionalTax2, additionalTax2Caption: item?.additionalTax2Caption };    
    if(!(user === "" || user==="guest" )){
      newItem = { locationCode: selectedLocation.code, brand:item.brand, id: item?.id??"", no:item?.no??"", name: item?.name??"Product Name", quantity: quantity??0, image: item?.image??"../../assets/images/product/blog-1.jpg", description: item?.description??"", price: item?.price??0, services: fields, salesTax: item?.salesTax, additionalTax1: item?.additionalTax1, additionalTax1Caption: item?.additionalTax1Caption, additionalTax2: item?.additionalTax2, additionalTax2Caption: item?.additionalTax2Caption };    
    }
    
      dispatch(clearCart());
      // console.log(" 2 ", item, newItem);
      dispatch(addToCart(newItem));
      // localStorage.setItem("cart", JSON.stringify([...cart, newItem]));
      setShowConfirmationModal(false);
      if(redirect == true)
        navigate('/cart')
  }

if(showConfirmationModal === true) return (
<Dialog
      open={showConfirmationModal}
      onClose={() => setShowConfirmationModal(false)}
      PaperProps={{
        sx: { borderRadius: "10px", padding: "10px", height: getHeight() },
      }}
    >
      <DialogContent sx={{ textAlign: "center" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ fontSize: "20px", marginBottom: "20px" }}>
              When Switching between stores items in cart will be lost.
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "20px" }}>
              Are you sure you want to proceed?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} display={"flex"} style={{ justifyContent: "space-evenly" }}>
              <Grid item xs={5}>
                <Button
                  variant="contained"
                  color="success"
                  fullWidth
                  onClick={() => handleStillAddToCart(product,false)}
                  sx={{ fontSize: getFontSize(), padding: "15px", height: "100%" }}
                >
                  Continue
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button variant="contained" color="error" fullWidth onClick={() => {setOpen(false); setShowConfirmationModal(false);}} sx={{ fontSize: getFontSize(), padding: "15px", height: "100%" }}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
)
else if(open === true){
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: {
          borderRadius: "10px",
          // width: "80%",
          maxWidth: "750px",
          maxHeight: "100vh",
          // maxWidth:"70vw"
        },
      }}
    >
      <DialogContent>
        <IconButton
          style={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
          color="error"
          onClick={() => setOpen(false)}
        >
          <ClearIcon />
        </IconButton>
        <Typography
          variant="h6"
          style={{
            marginTop: "10px",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          Please Select Your Services
        </Typography>
        <div style={{ marginTop: 20, height: "100%"}} >
          <MultiToggle
            fields={fields?.length===0?[]:fields}
            selectAll={selectAll}
            handleToggleAllChange={handleToggleAllChange}
            onToggleChange={handleToggleChange}
            expandedIndex={expandedIndex}
            setExpandedIndex={setExpandedIndex}
          />
        </div>
        {!isCart && (
        <div style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between", // Ensures one button on each side
          marginTop: "10px"
        }}>
           
          <Grid item xs={12}>
            <Grid container spacing={1} display={"flex"} style={{ justifyContent: "space-evenly" }}>
              <Grid item xs={5}>
                <Button
                  variant="contained"
                  color="success"
                  fullWidth
                  onClick={() => handleAddToCart?.(product,false)}
                  sx={{ padding: "15px", height: "100%", textTransform: "none",fontSize:"1rem" }}
                >
                    Select & Keep Shopping
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button variant="contained" color="error" fullWidth onClick={() => handleAddToCart?.(product,true)} sx={{  padding: "15px", height: "100%", textTransform: "none",fontSize:"1rem" }}>
                Select & Go To Cart
                </Button>
              </Grid>
            </Grid>
          </Grid>





  </div>
)}

      </DialogContent>
    </Dialog>
  );
}
else{
  return <></>;
}
}

export default OptionalServicesModal;
