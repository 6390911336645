import { useState } from 'react';
import CartItem from '../cartItem/CartItem';
import { CartItem as CartItemType } from '../../../models/app';
import {Grid} from "@mui/material";

const CartTable = ({ updateCart, cartItems, setCartItems, calculateTotal, removeFromCart, user }:any) => {
  const [hasAdditionalTax] = useState<Boolean>(cartItems.some((item: CartItemType) => item.additionalTax1 !== "0"))

  if (true) {

    return (
        <Grid container spacing={{xs: 1, md: 1}} columns={{xs: 12, sm: 12, md: 12}} sx={{backgroundColor:"lightgrey", paddingTop:"20px",paddingBottom:"5px"}}>
          {cartItems.map((product: any, index: number) => (
              <CartItem
                  updateCart={updateCart}
                  calculateTotal={calculateTotal}
                  setCartItems={setCartItems}
                  removeFromCart={removeFromCart}
                  key={`${product?.SystemId}-${index}`}
                  product={product}
                  hasAdditionalTax={hasAdditionalTax}
                  user={user}
              />
          ))}
        </Grid>
    );
  }


};

export default CartTable;
